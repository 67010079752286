import React, { useEffect, useMemo, useState } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import type { ExcalidrawImperativeAPI } from '@excalidraw/excalidraw/types/types';

import { RootState, store, useAppDispatch, useAppSelector } from '../../store';
import ScreenShareElements from '../media-elements/screenshare';
import AudioElements from '../media-elements/audios';
import SharedNotepadElement from '../shared-notepad';
import Whiteboard from '../whiteboard';
import ExternalMediaPlayer from '../external-media-player';
import DisplayExternalLink from '../display-external-link';
import VideosComponent from '../media-elements/videos';
import { doRefreshWhiteboard } from '../../store/slices/whiteboard';
import { CurrentConnectionEvents, IConnectLivekit } from '../../helpers/livekit/types';
import {
  updateDisplayExternalLinkRoomModal,
  updateIsActiveChatPanel,
  updateIsActiveMicrophone,
  updateIsActiveParticipantsPanel,
  updateIsActiveWebcam,
} from '../../store/slices/bottomIconsActivitySlice';
import SpeechToTextService from '../speech-to-text-service';
import { useCallbackRefState } from '../whiteboard/helpers/hooks/useCallbackRefState';
import { savePageData } from '../whiteboard/helpers/utils';
import {
  getCurrentConnection,
  isCurrentUserRecorder,
} from '../../helpers/livekit/utils';
import { getAudioPreset, getDevices, getWebcamResolution } from '../../helpers/utils';
import { ConnectionState, Track, createLocalAudioTrack, createLocalTracks, createLocalVideoTrack } from 'livekit-client';
import { addAudioDevices, addVideoDevices, updateSelectedAudioDevice, updateSelectedVideoDevice } from '../../store/slices/roomSettingsSlice';
import { IMediaDevice } from '../../store/slices/interfaces/roomSettings';

interface IMainComponentsProps {
  isActiveWhiteboard: boolean;
  isActiveExternalMediaPlayer: boolean;
  isActiveDisplayExternalLink: boolean;
  isActiveScreenSharingView: boolean;
}

const activateWebcamsViewSelector = createSelector(
  (state: RootState) => state.roomSettings,
  (roomSettings) => roomSettings.activateWebcamsView,
);

const activateSpeechServiceSelector = createSelector(
  (state: RootState) =>
    state.session.currentRoom.metadata?.room_features
      .speech_to_text_translation_features,
  (speech_to_text_translation_features) =>
    speech_to_text_translation_features?.is_enabled,
);

const isActiveSharedNotepadSelector = createSelector(
  (state: RootState) =>
    state.session.currentRoom.metadata?.room_features.shared_note_pad_features,
  (shared_note_pad_features) => shared_note_pad_features?.is_active,
);

const virtualBackgroundSelector = createSelector(
  (state: RootState) => state.bottomIconsActivity,
  (bottomIconsActivity) => bottomIconsActivity.virtualBackground,
);

const MainComponents = ({
  isActiveWhiteboard,
  isActiveExternalMediaPlayer,
  isActiveDisplayExternalLink,
  isActiveScreenSharingView,
}: IMainComponentsProps) => {
  const dispatch = useAppDispatch();
  const isRecorder = isCurrentUserRecorder();
  const currentConnection = getCurrentConnection();

  const activateWebcamsView = useAppSelector(activateWebcamsViewSelector);
  const activateSpeechService = useAppSelector(activateSpeechServiceSelector);
  const isActiveSharedNotepad = useAppSelector(isActiveSharedNotepadSelector);
  const virtualBackground = useAppSelector(virtualBackgroundSelector);

  const [showVerticalVideoView, setShowVerticalVideoView] =
    useState<boolean>(false);
  const [hasVideoElms, setHasVideoElms] = useState<boolean>(false);
  const [showVideoElms, setShowVideoElms] = useState<boolean>(false);
  const [isActiveScreenShare, setIsActiveScreenShare] =
    useState<boolean>(false);
  const [virtualBgLocalTrack, setVirtualBgLocalTrack] = useState<MediaStream>();
  const [excalidrawAPI, excalidrawRefCallback] =
    useCallbackRefState<ExcalidrawImperativeAPI>();

  useEffect(() => {
    setHasVideoElms(currentConnection.videoSubscribersMap.size > 0);
    currentConnection.on(CurrentConnectionEvents.VideoStatus, setHasVideoElms);

    setIsActiveScreenShare(currentConnection.screenShareTracksMap.size > 0);
    currentConnection.on(
      CurrentConnectionEvents.ScreenShareStatus,
      setIsActiveScreenShare,
    );
    return () => {
      currentConnection.off(
        CurrentConnectionEvents.VideoStatus,
        setHasVideoElms,
      );
      currentConnection.off(
        CurrentConnectionEvents.ScreenShareStatus,
        setIsActiveScreenShare,
      );
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !isActiveScreenShare &&
      !isActiveWhiteboard &&
      !isActiveExternalMediaPlayer &&
      !isActiveDisplayExternalLink
    ) {
      setShowVerticalVideoView(false);
    } else {
      setShowVerticalVideoView(true);
    }
  }, [
    isActiveScreenShare,
    isActiveWhiteboard,
    isActiveExternalMediaPlayer,
    isActiveDisplayExternalLink,
  ]);

  useEffect(() => {
    if (!activateWebcamsView) {
      setShowVideoElms(false);
      return;
    }
    setShowVideoElms(hasVideoElms);
  }, [activateWebcamsView, hasVideoElms]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isActiveWhiteboard) {
        dispatch(doRefreshWhiteboard());
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [showVideoElms, isActiveWhiteboard, dispatch]);

  const whiteboardElm = useMemo(() => {
    // if we disable whiteboard during that time we should collect elements from parent component
    // otherwise if we make it null then we won't be able to get last state
    if (
      ((isActiveWhiteboard && isActiveScreenShare) || !isActiveWhiteboard) &&
      excalidrawAPI
    ) {
      const s = store.getState();
      const isPresenter = s.session.currentUser?.metadata?.is_presenter;
      // we'll only do it for presenter
      if (isPresenter) {
        const lastPage = s.whiteboard.currentPage;
        savePageData(excalidrawAPI, lastPage);
      }
    }
    // for whiteboard, it's better to null not hide
    return !isActiveScreenShare && isActiveWhiteboard ? (
      <Whiteboard onReadyExcalidrawAPI={excalidrawRefCallback} />
    ) : null;
    //eslint-disable-next-line
  }, [isActiveScreenShare, isActiveWhiteboard]);

  // we can't disable to show both external player & link.
  // So, external-media-player will be first priority
  const externalMediaPlayerElm = useMemo(() => {
    let classNames = 'hidden';
    if (
      (isActiveScreenSharingView && isActiveScreenShare) ||
      isActiveWhiteboard
    ) {
      classNames = 'hidden';
    } else if (isActiveExternalMediaPlayer) {
      if (!isRecorder) {
        setTimeout(() => {
          dispatch(updateIsActiveChatPanel(false));
          dispatch(updateIsActiveParticipantsPanel(false));
        }, 200);
      }
      classNames =
        'Div-external-media-player w-full flex items-center justify-center';
    }

    return (
      <div className={classNames}>
        <ExternalMediaPlayer />
      </div>
    );
    //eslint-disable-next-line
  }, [
    isActiveScreenSharingView,
    isActiveScreenShare,
    isActiveWhiteboard,
    isActiveExternalMediaPlayer,
  ]);

  const displayExternalLinkElm = useMemo(() => {
    let classNames = 'hidden';
    if (
      (isActiveScreenSharingView && isActiveScreenShare) ||
      isActiveWhiteboard ||
      isActiveExternalMediaPlayer
    ) {
      classNames = 'hidden';
    } else if (isActiveDisplayExternalLink) {
      if (!isRecorder) {
        setTimeout(() => {
          dispatch(updateIsActiveChatPanel(false));
          dispatch(updateIsActiveParticipantsPanel(false));
        }, 200);
      }
      classNames = 'w-full';
    }

    return (
      <div id="external-link-container" className={classNames}>
        <DisplayExternalLink />
      </div>
    );
    //eslint-disable-next-line
  }, [
    isActiveScreenSharingView,
    isActiveScreenShare,
    isActiveWhiteboard,
    isActiveExternalMediaPlayer,
    isActiveDisplayExternalLink,
  ]);

  // this will help to reset position, if something went wrong
  const sharedNotepadElm = useMemo(() => {
    if (isActiveSharedNotepad) {
      return <SharedNotepadElement />;
    }
    return null;
  }, [isActiveSharedNotepad]);

  const cssClasses = useMemo(() => {
    const cssClasses: Array<string> = [];
    if (isActiveScreenSharingView && isActiveScreenShare) {
      cssClasses.push(
        'middle-fullscreen-wrapper share-screen-wrapper is-share-screen-running',
      );
      if (showVideoElms && showVerticalVideoView) {
        cssClasses.push('verticalsWebcamsActivated');
      }
    } else {
      if (showVideoElms && !showVerticalVideoView) {
        cssClasses.push('h-full');
      } else if (showVideoElms && showVerticalVideoView) {
        cssClasses.push(
          'middle-fullscreen-wrapper h-full flex verticalsWebcamsActivated',
        );
      } else {
        cssClasses.push('middle-fullscreen-wrapper h-full flex');
      }
    }
    return cssClasses.join(' ');
  }, [
    isActiveScreenSharingView,
    isActiveScreenShare,
    showVideoElms,
    showVerticalVideoView,
  ]);

  // start auto web cam logic

  const createVideoDeviceStream = async (deviceId: string) => {
    if (virtualBackground.type === 'none') {
      const resolution = getWebcamResolution();
      const track = await createLocalVideoTrack({
        deviceId: {
          exact: deviceId,
          ideal: deviceId,
        },
        resolution,
      });
      await currentConnection.room.localParticipant.publishTrack(track);
      dispatch(updateIsActiveWebcam(true));
    } else {
      const constraints: MediaStreamConstraints = {
        video: {
          // width: { min: 160, ideal: 320 },
          // height: { min: 90, ideal: 180 },
          deviceId: {
            exact: deviceId,
            ideal: deviceId,
          },
        },
      };
      const mediaStream =
        await navigator.mediaDevices.getUserMedia(constraints);
      setVirtualBgLocalTrack(mediaStream);
    }
    return;
  };

  const shouldShowWebcam = () => {
    const session = store.getState().session;
    const room_features = session.currentRoom.metadata?.room_features;
    const currentUser = session.currentUser;
    if (!room_features?.allow_webcams) {
      return false;
    } else if (
      room_features?.admin_only_webcams &&
      !currentUser?.metadata?.is_admin
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (
      currentConnection
      && currentConnection.room.state === ConnectionState.Connected
      && !isRecorder
    ) {
      console.info("MainComponents. Creating default video and audio tracks...");
      const getDeviceMics = async (): Promise<IMediaDevice[]> => {
        const mics = await getDevices('audioinput');
        const audioDevices: Array<IMediaDevice> = mics.map((mic) => {
          const device: IMediaDevice = {
            id: mic.deviceId,
            label: mic.label,
          };
          return device;
        });
        return audioDevices;
      };

      const timeout = setTimeout(() => {
        console.info("MainComponents. setTimeout called.");
        getDeviceMics().then(
          (deviceMics) => {
            console.info(`MainComponents.getDeviceMics found ${deviceMics.length} audioDevices`);
            if (deviceMics.length) {
              dispatch(addAudioDevices(deviceMics));
              createLocalTracks({
                audio: {
                  deviceId: deviceMics[0].id,
                },
                video: false,
              }).then(
                async (localTracks) => {
                  for (let i = 0; i < localTracks.length; i++) {
                    const track = localTracks[i];
                    if (track.kind === Track.Kind.Audio) {
                      console.info("MainComponents. Creating default audio track...");
                      await currentConnection.room.localParticipant.publishTrack(
                        track,
                        {
                          audioPreset: getAudioPreset(),
                        }
                      ).then(
                        async (localTrackPublication) => {
                          console.info("MainComponents. Created default audio track.");
                          dispatch(updateIsActiveMicrophone(true));
                          console.info(`MainComponents. deviceId. ${deviceMics[0].id}`);
                          dispatch(updateSelectedAudioDevice(deviceMics[0].id));
                          console.info("MainComponents. Calling switchActiveDevice audioinput.");
                          await currentConnection.room.switchActiveDevice('audioinput', deviceMics[0].id);
                        }
                      ).catch(
                        (err) => {
                          console.warn("MainComponents publishTrack error");
                          console.error(err);
                        }
                      );
                    }
                  }

                  if (shouldShowWebcam()) {
                    const getDeviceWebcams = async () => {
                      const mics = await getDevices('videoinput');
                      console.info(`MainComponents.getDeviceWebcams found ${mics.length} mics`);
                      const videoDevices: Array<IMediaDevice> = mics.map((mic) => {
                        const device: IMediaDevice = {
                          id: mic.deviceId,
                          label: mic.label,
                        };
                        console.info("MainComponents device", device);
                        return device;
                      });
                      // setSelectWebcam(mics[0].deviceId);
                      console.info(`MainComponents.getDeviceWebcams found ${videoDevices.length} videoDevices`);
                      return videoDevices;
                    };
                    const webCams = await getDeviceWebcams();
                    if (webCams.length) {
                      dispatch(addVideoDevices(webCams));
                      const defaultWebCam = webCams[0];
                      await createVideoDeviceStream(defaultWebCam.id).then(
                        async () => {
                          dispatch(updateSelectedVideoDevice(defaultWebCam.id));
                          console.info("MainComponents. Calling switchActiveDevice videoinput.");
                          await currentConnection.room.switchActiveDevice('videoinput', defaultWebCam.id);
                        }
                      ).catch(
                        (err) => {
                          console.warn("MainComponents createVideoDeviceStream error");
                          console.error(err);
                        }
                      );
                    }
                  }
                  console.info("MainComponents. Created default video and audio tracks.");
                  if (store.getState().session.currentUser?.metadata?.is_admin) {
                    if (
                      store.getState().session.currentRoom.metadata?.room_features.display_external_link_features?.link
                    ) {
                      dispatch(updateDisplayExternalLinkRoomModal(true));
                    }
                  }
                }
              ).catch(
                (err) => {
                  console.warn("MainComponents createLocalTracks error");
                  console.error(err);
                }
              );
            }
          }
        ).catch(
          (err) => {
            console.warn("MainComponents getDeviceMics error");
            console.error(err);
          }
        )
      }, 1000);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, [dispatch, currentConnection]);

  // end auto webcam logic

  return (
    <>
      <div className={cssClasses}>
        {sharedNotepadElm}
        {activateWebcamsView ? (
          <VideosComponent isVertical={showVerticalVideoView} />
        ) : null}
        {isActiveScreenSharingView ? <ScreenShareElements /> : null}
        {whiteboardElm}
        {externalMediaPlayerElm}
        {displayExternalLinkElm}
        {activateSpeechService ? <SpeechToTextService /> : null}
      </div>
      <AudioElements />
    </>
  );
};

export default MainComponents;
